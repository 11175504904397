import { Button } from 'antd';
import { useRouter } from 'next/router';
import {
  CACHE_REVALIDATION_TIME,
  STATIC_DATA_KEYS,
  staticUiLabels
} from '../common/constants';
import { staticDataApi } from '../common/utils';

export default function FourOhFour({ title, description }) {
  const router = useRouter();
  const handleGoBack = () => {
    router.back();
  };
  return (
    <div className="error-page-wrapper text-center">
      <div className="container">
        <h1 className="error-heading text-black-90">404</h1>
        <h3 className="display-h3 mt-16 text-black-90 ">{title}</h3>
        <div className="devider-error" />
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: description
          }}
          className="paragraph-l text-black-80 mb-32"
        />
        <Button
          onClick={handleGoBack}
          className="cs-button"
          type="primary"
          size="large"
        >
          Go Back
        </Button>
      </div>
    </div>
  );
}

export async function getStaticProps() {
  const configs = {
    revalidate: CACHE_REVALIDATION_TIME
  };

  try {
    const { data: uiLabels = {} } = await staticDataApi?.get(
      `/${STATIC_DATA_KEYS.UI_LABELS}/en.json`
    );
    return {
      props: {
        title:
          uiLabels?.['404_PAGE_TITLE']?.value ?? staticUiLabels.PAGE_TITLE_400,
        description:
          uiLabels?.['404_PAGE_DESCRIPTION']?.value ??
          staticUiLabels.PAGE_DESCRIPTION_400
      },
      ...configs
    };
  } catch (error) {
    return {
      props: {
        title: staticUiLabels.PAGE_TITLE_400,
        description: staticUiLabels.PAGE_DESCRIPTION_400
      },
      ...configs
    };
  }
}
